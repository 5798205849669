.Refresh {
  height: 100%;
  color: #23263b;
  cursor: pointer;
  background: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
  font-family: inherit;
  font-size: .875rem;
  line-height: 2rem;
  display: flex;
  box-shadow: 0 1px #23263b0d;
}

.Refresh:hover {
  background: linear-gradient(-180deg, #fff, #f5f5fa);
}

.Refresh svg {
  transition: transform .5s ease-out;
}

.Refresh:active svg {
  transform: rotate(360deg);
}

.Tabs-header {
  display: flex;
}

.Tabs-title {
  color: #23263b;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  flex: 1;
  padding: 0 1rem;
  font-family: inherit;
  font-size: .875rem;
  line-height: 2rem;
  box-shadow: 0 1px #23263b0d;
}

.Tabs-title:first-child {
  border-radius: 3px 0 0 3px;
}

.Tabs-title:last-child {
  border-radius: 0 3px 3px 0;
}

.Tabs-title:hover {
  background: linear-gradient(-180deg, #fff, #f5f5fa);
}

.Tabs-title + .Tabs-title {
  margin-left: -1px;
}

.Tabs-title--active {
  z-index: 1;
  font-weight: bold;
  box-shadow: inset 0 1px 4px #777aaf66, inset 0 1px 1px #777aaf66, 0 1px #23263b0d;
}

.Tabs-list {
  margin-top: 1rem;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  max-width: 1000px;
  margin: 0 auto;
  padding: .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.Container {
  grid-template-columns: minmax(min-content, 200px) 1fr;
  align-items: flex-start;
  gap: .5rem;
  display: grid;
}

.Search {
  gap: .5rem;
  display: grid;
}

.Search-header {
  grid-template-columns: 1fr auto auto auto;
  align-items: flex-start;
  gap: .5rem;
  display: grid;
}

.CurrentRefinements {
  flex-wrap: wrap;
  align-items: center;
  gap: .5em;
  display: flex;
}

.Hit-label {
  flex: 1;
  margin-right: 1rem;
}

.Hit-price {
  color: #789;
}

.Pagination {
  justify-content: center;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
}

.ais-CurrentRefinements-label {
  text-transform: capitalize;
}

.ais-PoweredBy {
  margin-top: .5rem;
}

.ais-PoweredBy-logo {
  height: 1rem;
}

/*# sourceMappingURL=index.48b7315b.css.map */
