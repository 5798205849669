* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  padding: 0.5rem;
  max-width: 1000px;
  margin: 0 auto;
}

.Container {
  display: grid;
  align-items: flex-start;
  grid-template-columns: minmax(min-content, 200px) 1fr;
  gap: 0.5rem;
}

.Search {
  display: grid;
  gap: 0.5rem;
}

.Search-header {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr auto auto auto;
  gap: 0.5rem;
}

.CurrentRefinements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;
}

.Hit-label {
  flex: 1;
  margin-right: 1rem;
}

.Hit-price {
  color: lightslategray;
}

.Pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  margin: 0 auto;
}

.ais-CurrentRefinements-label {
  text-transform: capitalize;
}

.ais-PoweredBy {
  margin-top: 0.5rem;
}

.ais-PoweredBy-logo {
  height: 1rem;
}
